@import '@/styles/index';
@import '@/styles/wrap';

.details {
  color: $blackColor;
  font-size: 16px;
  font-weight: 400;
  line-height: 1.5;
  .title {
    text-align: center;
    padding-bottom: 30px;
    margin-bottom: 45px;
    border-bottom: 2px solid $blueColor;
    .type {
      font-weight: 300;
    }
    h5 {
      font-size: 26px;
      font-weight: 500;
      margin-bottom: 15px;
    }
    .time {
      font-size: 14px;
      color: #bbb;
    }
  }
  .content {
    line-height: 2;
    text-align: justify;
    word-break: break-all;
    img {
      display: block;
      max-width: 100%;
      max-height: 800px;
      height: auto;
      margin: auto;
      object-fit: contain;
      margin-bottom: 20px;
    }
    video, iframe {
      width: 100%;
      height: auto;
      aspect-ratio: 2 / 1;
    }
    pre {
      white-space: normal;
    }
    .video {
      width: 100%;
      height: 0;
      padding-top: 56.25%;
      position: relative;
      margin-bottom: 20px;
      video {
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
      }
    }
    p {
      margin-bottom: 15px;
    }
  }
  .more {
    margin-top: 50px;
    border-top: 1px solid $borderGreyColor;
    padding-top: 45px;
    .prev, .next {
      margin-bottom: 5px;
      font-size: 16px;
      line-height: 2;
    }
  }
}


@include xs {
  .wrap .manBox {
    padding-bottom: po(50);
    & + .details {
      padding-top: po(50);
      padding-left: 20px;
      padding-right: 20px;
      &::before {
        content: '';
        position: absolute;
        left: po(50, 670);
        right: po(50, 670);
        height: 1px;
        top: 0;
        background-color: $borderGreyColor;
      }
      .content {
        padding: 0 po(45, 670);
      }
      .title {
        display: none;
      }
    }
  }
  .details {
    font-size: 14px;
    .title {
      margin-bottom: po(60, 670);
      padding-bottom: po(35, 670);
      .type {
        font-size: 14px;
        margin-bottom: po(12, 670);
      }
      h5 {
        font-size: 20px;
        margin-bottom: po(20, 670);
      }
      .time {
        font-size: 12px;
      }
    }
    .more {
      margin-top: po(90, 670);
      padding-top: po(100, 670);
      border: none;
      position: relative;
      &::before {
        content: '';
        position: absolute;
        width: 100%;
        height: 1px;
        top: 0;
        background-color: $borderGreyColor;
        transform: scaleY(.5);
      }
      div:empty {
        display: none;
      }
      .prev, .next {
        display: none;
      }
    }
  }
}