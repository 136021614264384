@import '@/styles/index';
.main {
  width: 1080px;
}
.img {
  position: relative;
  z-index: 2;
  img {
    position: relative;
    display: block;
    z-index: 2;
    object-fit: cover;
    width: 100%;
    height: 100%;
  }
}
.text {
  position: relative;
  box-sizing: border-box;
  color: $blackColor;
  border-radius: 10px;
  background-color: $lightBlueColor;
  .year {
    position: relative;
    z-index: 2;
    font-weight: 700;
    font-size: 20px;
    line-height: 1;
    margin-bottom: 20px;
    display: flex;
    align-items: center;
    &::before {
      content: '';
      display: inline-block;
      width: 10px;
      height: 16px;
      margin-right: 10px;
      background-color: $darkBlueColor;
    }
  }
  .content {
    position: relative;
    z-index: 2;
    font-weight: 400;
    font-size: 16px;
    line-height: 1.5;
    padding-left: 20px;
  }
}
.type1, .type2, .type3 {
  margin-top: 60px;
}
.type1 {
  .img {
    width: 905px;
    height: 280px;
  }
  .text {
    width: 920px;
    height: 295px;
    margin-left: 90px;
    margin-top: -150px;
    padding-top: 190px;
    padding-left: 88px;
  }
}
.type2 {
  .img {
    width: 540px;
    height: 569px;
  }
  .textBox {
    background-color: $lightBlueColor;
    position: absolute;
    border-radius: 10px;
    z-index: 4;
    top: 50%;
    right: 0;
    transform: translateY(-50%);
    display: flex;
    justify-content: center;
    flex-direction: column;
    width: 815px;
    box-sizing: border-box;
    padding: 50px 55px 50px 45px;
  }
  .text {
    margin-bottom: 30px;
    &:last-child {
      margin-bottom: 0;
    }
  }
}
.type3 {
  overflow: hidden;
  clear: both;
  margin-bottom: 80px;
  .text {
    padding-top: 50px;
    padding-left: 90px;
    width: 805px;
    height: 215px;
    margin-left: 90px;
    margin-bottom: 20px;
  }
  .img {
    margin-top: -67px;
    width: 890px;
    height: 277px;
    float: right;
  }
}

@include xs {
  .main {
    width: 100%;
  }
  .img {
    img {
      position: absolute;
      top: 0;
    }
  }
  .text {
    .content {
      font-size: 14px;
    }
  }
  .type1, .type2, .type3 {
    margin-top: po(100);
    padding-left: 0;
    padding-right: 0;
  }
  .type1 {
    &::after {
      content: '';
      position: absolute;
      background: $lightBlueColor;
      top: 0;
      right: 0;
      margin-top: po(60);
    }
    .img, &::after {
      width: po(665);
      height: 0;
      padding-top: po(255);
    }
    .text {
      background: none;
      width: 100%;
      height: auto;
      margin: 0;
      padding: po(125) po(85) 0 po(85);
    }
  }
  .type2 {
    .img {
      width: po(680);
      height: 0;
      padding-top: po(680);
      margin: 0 auto;
    }
    .textBox {
      width: 100%;
      position: relative;
      z-index: 1;
      transform: translateY(0);
      top: 0;
      margin-top: po(-340);
      padding: po(420) po(85) po(90) po(85);
      border-radius: 0;
      .text {
        margin-bottom: po(70, 580);
        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }
  .type3 {
    display: flex;
    flex-direction: column;
    &::after {
      content: '';
      position: absolute;
      background: $lightBlueColor;
      top: 0;
      left: 0;
      margin-top: po(60);
    }
    .img, &::after {
      width: po(665);
      height: 0;
      padding-top: po(255);
    }
    .img {
      margin-left: po(85);
      flex-grow: 0;
      flex-shrink: 0;
      margin-top: 0;
    }
    .text {
      order: 3;
      background: none;
      width: 100%;
      height: auto;
      margin: 0;
      padding: po(125) po(85) 0 po(85);
    }
  }
}