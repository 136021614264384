@import '@/styles/index';

.nav {
  padding: 60px 0;
  box-sizing: border-box;
  .nav_control {
    display: none;
  }
  .nav_content {
    display: flex;
    justify-content: space-between;
    flex-wrap: nowrap;
  }
  .item {
    width: 33.1%;
    height: 60px;
    text-align: center;
    font-size: 20px;
    font-weight: 500;
    line-height: 60px;
    border-radius: 10px;
    color: $blackColor;
    background-color: $lightBlueColor;
    &.active {
      color: $whiteColor;
      background-color: $darkBlueColor;
    }
  }
}
.content {
  padding-bottom: 70px;
  .title {
    height: 60px;
    padding: 0 30px;
    margin-bottom: 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: $blueColor;
    background: $bgGreyColor;
    border-radius: 10px;
    font-size: 18px;
    &:last-child {
      margin-bottom: 30px;
    }
    span {
      color: $blackColor;
    }
    & + .text {
      font-size: 16px;
      padding: 0 30px;
      line-height: 1.5;
      margin-bottom: 30px;
      display: none;
    }
    &.active{
      & + .text {
        display: block;
      }
    }
  }
}


@include xs {
  .nav {
    display: block;
    position: relative;
    z-index: 3;
    padding: po(100) 0 0;
    width: calc(100% - 40px);
    height: auto;
    margin:0 20px po(40, 670);
    .nav_control {
      height: 65px;
      background-color: $darkBlueColor;
      color: $blueColor;
      margin-bottom: 0;
      display: flex;
      align-items: center;
      justify-content: space-between;
      font-size: 20px;
      font-weight: 500;
      padding: 0 25px;
      border-radius: 10px;
      span {
        color: $whiteColor;
      }
    }
    .nav_content {
      display: none;
      &.nav_show {
        display: block;
        position: absolute;
        width: 100%;
        flex-direction: column;
        border: 1px solid $blueColor;
        border-radius: 10px;
        background-color: $whiteColor;
      }
    }
    .item {
      display: block;
      width: 100%;
      height: 55px;
      font-size: 18px;
      line-height: 55px;
      text-indent: 25px;
      text-align: left;
      margin-bottom: 0;
      border-radius: 10px;
      color: $darkBlueColor;
      background: none;
      &.active {
        color: $darkBlueColor;
        background-color: $lightBlueColor;
      }
    }
  }
  .content {
    padding-bottom: po(100);
    .title {
      height: 55px;
      font-size: 16px;
      padding: 0 15px 0 25px;
      margin-bottom: po(20, 670);
      &:last-child {
        margin-bottom: 0;
      }
      & + .text {
        font-size: 14px;
        padding: 0 25px;
        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }
}