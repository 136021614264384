@import '@/styles/index';

.title {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  margin-bottom: 20px;
  .name {
    font-weight: 500;
    font-size: 26px;
  }
  a.more {
    color: $blueColor;
    font-size: 16px;
    &:hover {
      color: $darkBlueColor;
    }
  }
  &.border {
    padding-bottom: 20px;
    border-bottom: 2px solid $borderGreyColor;
  }
}
.main {
  z-index: 2;
}
.titleCenter {
  font-size: 26px;
  font-weight: bold;
  line-height: 1;
  text-align: center;
  margin-bottom: 38px;
}
a.moreCenter {
  width: 180px;
  height: 55px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 50px auto 0;
  background-color: $darkBlueColor;
  border: 1px solid $darkBlueColor;
  color: $whiteColor;
  font-size: 16px;
  border-radius: 10px;
  &:hover {
    background: none;
    color: $darkBlueColor;
    border-color: $darkBlueColor;
  }
}

@include xs {
  .title {
    margin-bottom: po(60 , 670);
    .name {
      font-size: 24px;
    }
    &.border {
      padding-bottom: po(40, 670);
    }
    &.hasTags {
      margin-bottom: 100px;
    }
    .more {
      font-size: 14px;
    }
  }
  .titleCenter  {
    font-size: 24px;
    margin-bottom: po(40);
  }
  a.moreCenter {
    position: relative;
    z-index: 2;
    font-size: 14px;
    margin-top: po(60);
    width: po(260 , 670);
    height: 40px;
    line-height: 40px;
  }
}