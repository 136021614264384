@import '@/styles/index';

.card {
  position: relative;
  font-weight: 400;
  color: $blackColor;
  border-radius: 20px;
  box-shadow: 0px 0px 16px 0px rgba(192, 211, 227, 0.5);
  background: white;
  .content {
    display: flex;
    justify-content: space-between;
    padding: 60px 50px 50px;
  }
  .detailed {
    width: 100%;
    height: 140px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  .title {
    height: 28px * 3;
    overflow: hidden;
    font-size: 16px;
    line-height: 28px;
    font-weight: 500;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient:vertical;
  }
  &.mini .title{
    height: 28px * 2;
    -webkit-line-clamp: 2;
    font-weight: normal;
  }
  .type {
    position: relative;
    width: 50px;
    height: 50px;
    font-weight: 400;
    font-size: 10px;
    flex-shrink: 0;
    text-align: center;
    display: flex;
    margin-right: 25px;
    align-items: center;
    justify-content: center;
    border-radius: 10px;
    background-color: $lightBlueColor;
    &::after {
      content: attr(data-type);
      color: $darkBlueColor;
      text-transform: uppercase;
    }
  }
  .time {
    font-size: 14px;
    display: flex;
    color: $blueColor;
    align-items: center;
    justify-content: space-between;
  }
}
.image {
  padding: 25px 20px 25px;
  display: block;
  font-size: 18px;
  line-height: 1.5;
  font-weight: 400;
  text-align: center;
  color: $lightBlackColor;
  border-radius: 10px;
  background: $whiteColor;
  &.shadowMode {
    .placeholder, .imgLink {
      box-shadow: 0px 0px 3px 0px #C4C4C4;
    }
  }
  &.videoMode {
    padding: 40px 40px 25px;
    background: $bgGreyColor;
  }
  &.borderMode {
    padding: 0;
    .imgLink {
      border-radius: 10px;
      box-shadow: none;
      border: 1px solid $borderGreyColor;
    }
  }
  .placeholder, .imgLink {
    width: 100%;
    height: 0;
    margin: 0 auto 20px;
  }
  .placeholder {
    background: url(./images/logo.png) center center no-repeat #e8e8e8;
    background-size: 30%;
    transition: all .5s ease-out;
  }
  .imgLink {
    position: relative;
    display: block;
    overflow: hidden;
    img {
      position: absolute;
      height: 100%;
      object-fit: cover;
      background-color: #fff;
      left: 50%;
      top: 50%;
      display: block;
      box-sizing: border-box;
      transform: translate3d(-50%, -50%, 0);
      transition: all .5s ease-out;
    }
  }
  .video {
    position: relative;
    width: 100%;
    height: 0;
    padding-top: 56.25%;
    margin-bottom: 30px;
    video {
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
    }
  }
  .title {
    padding: 0 15px;
    font-weight: 500;
    text-align: center;
    height: 24px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
}
.text {
  font-size: 16px;
  line-height: 1.5;
  font-weight: 400;
  color: $blackColor;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  flex-wrap: wrap;
  padding: 20px 0 40px;
  border-bottom: 1px solid $borderGreyColor;
  .title {
    font-size: 14px;
    font-weight: 300;
  }
  .time {
    font-size: 14px;
    color: $blueColor;
  }
  .content {
    padding-top: 15px;
    width: 100%;
    font-size: 18px;
    font-weight: 500;
    margin-bottom: 20px;
  }
  .icon {
    color: $blueColor;
    margin-left: -5px;
  }
  &.item {
    align-items: center;
    border-bottom: none;
    .time {
      order: 3;
      color: #CBDBE7;
    }
    .content {
      font-size: 16px;
      height: 95px;
      margin-bottom: 0;
    }
    .icon {
      display: block;
      width: 20px;
      height: 20px;
      padding: 7px 9px 8px 6px;
      border-radius: 50%;
      background-color: #CBDBE7;
      order: 4;
      margin: 0;
      color: $whiteColor;
      & > * {
        display: block;
      }
    }
    &:hover {
      background: #FFFFFF;
      box-shadow: 0px 0px 15px 0px rgba(192, 211, 227, 0.5);
      .content {
        color: $darkBlueColor;
      }
      .icon {
        background-color: $darkBlueColor;
      }
    }
  }
}
.man {
  font-weight: 400;
  font-size: 16px;
  line-height: 1.5;
  padding:0 0 30px 30px;
  display: block;
  &:only-child {
    padding-bottom: 0;
  }
  .img {
    margin-right: 30px;
    display: block;
    width: 200px;
    height: 265px;
    flex-shrink: 0;
    position: relative;
    overflow: hidden;
    img {
      width: 200px;
      height: 265px;
      box-sizing: border-box;
      transform-origin: center;
      transition: all 1s ease-out;
    }
  }
  .name {
    position: relative;
    font-weight: 500;
    font-size: 20px;
    line-height: 1.8;
    display: flex;
    align-items: center;
    &::before {
      content: '';
      display: inline-block;
      width: .45em;
      height: 1em;
      margin-right: 20px;
      background-color: $darkBlueColor;
      margin-left: calc(-.45em - 20px);
    }
  }
  .title {
    font-size: 14px;
    font-weight: 300;
  }
  .message {
    padding-top: 20px;
  }
}
.service {
  position: relative;
  font-size: 14px;
  line-height: 24px;
  text-align: center;
  .content {
    display: flex;
    flex-direction: column;
    align-items: center;
    color: $blueColor;
    border-radius: 20px;
    background: $whiteColor;
    box-shadow: 0px 0px 15px 0px rgba(192, 211, 227, 0.5);
    padding: 80px 20px 40px;
  }
  .list {
    text-align: left;
    color: $greyColor;
    height: 145px;
  }
  .icon {
    width: 80px;
    height: 83px;
    margin-right: 0;
    margin-bottom: 25px;
    background: url(./images/icon.png) no-repeat;
    background-size: 267px 167px;
  }
  &:nth-child(1) .icon {
    background-position: 0 0;
  }
  &:nth-child(2) .icon {
    background-position: center 0;
  }
  &:nth-child(3) .icon {
    background-position: 100% 0;
  }
  .type {
    font-weight: 500;
    font-size: 16px;
    line-height: 30px;
    margin-bottom: 9px;
    position: relative;
    color: $blackColor;
  }
}
@include xs {
  .card {
    border-radius: 8px;
    box-shadow: 0px 0px 8px 0px rgba(192, 211, 227, 0.5);
    .content {
      padding: po(80, 670) po(50, 670);
    }
    .type {
      width: po(80 , 580);
      height: 0;
      padding-top: po(80 , 580);
      font-size: 10px;
      border-radius: 5px;
      margin-right: po(40, 670);
      &::after {
        position: absolute;
        width: calc(100% - 55px);
        top: 50%;
        transform: translateY(-50%);
      }
    }
    .time {
      font-size: 12px;
    }
    &.mini {
      border-radius: 10px;
      &:not(:last-child) {
        margin-bottom: po(50 , 670);
      }
      .content {
        &::before {
          content: attr(data-tips);
          font-size: 14px;
        }
        flex-direction: row;
        padding: 0 5px 0 20px;
        height: 50px;
        text-wrap: nowrap;
        white-space: nowrap;
        line-height: 50px;
        align-items: center;
      }
      .detailed {
        flex-direction: row;
        height: 50px;
      }
      .title {
        height: 50px;
        line-height: 50px;
        font-size: 14px;
        flex-grow: 1;
        flex-shrink: 1;
        text-wrap: wrap;
        -webkit-line-clamp: 1;
        margin-right: 10%;
      }
      .icon_right {
        flex-shrink: 0;
        display: block;
        margin-left: 5px;
      }
      a {
        text-overflow: ellipsis;
        overflow: hidden;
      }
      .type, .date {
        display: none;
      }
    }
    &:active {
      background-color: $darkBlueColor!important;
      color: $whiteColor!important;
      .time {
        color: currentColor;
      }
    }
  }
  .service {
    font-size: 14px;
    .list {
      height: auto;
      padding-bottom: 24px;
    }
    .content {
      position: relative;
      padding: po(90 , 580) 15px po(80 , 580);
      z-index: 2;
    }
    &::after {
      content: '';
      position: absolute;
      width: 100vw;
      height: auto;
      left: 50%;
      top: 215 / 750 * 100vw;
      bottom: -80 / 750 * 100vw;
      background-color: $lightBlueColor;
      transform: translate3d(-50%, 0, 0);
    }
  }
  .image {
    font-size: 16px;
    padding: po(60) po(50) po(50);
    &.videoMode {
      padding: po(60) po(50) po(50);
    }

    .placeholder, .imgLink {
      margin-bottom: po(50);
    }
  }
  .text {
    padding: po(20, 670) po(50, 670) po(80, 670);
    .time {
      font-size: 12px;
    }
    .content {
      font-size: 16px;
    }
    &.item {
      .content {
        height: auto;
        margin-bottom: 10px;
      }
      .icon {
        padding: 3px 5px 4px 2px;
      }
    }
  }
  .man {
    font-size: 14px;
    padding: 0 po(45, 670) po(50, 670);
    .img {
      position: relative;
      width: po(200);
      height: 0;
      padding-top: po(265);
      flex-shrink: 0;
      margin-right: 15px;
      img {
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
      }
    }
    .name {
      &::before {
        margin-right: 15px;
        margin-left: calc(-.45em - 15px);
      }
      font-size: 18px;
    }
    .title {
      font-size: 12px;
      &::after {
        width: 17.7%;
      }
    }
    .message {
      padding-top: po(20, 670);
    }
  }
}

@media (hover :hover) {
  .card:hover {
    background-color: $darkBlueColor!important;
    color: $whiteColor!important;
    .time {
      color: currentColor;
    }
    .type {
      background-color: #fff;
      color: $darkBlueColor;
    }
  }
  .image:hover {
    .title {
      color: $darkBlueColor;
    }
    .placeholder:hover {
      background-size: 33%;
    }
    img {
      transform: translate3d(-50%, -50%, 0) scale(1.1);
    }
  }
  .text {
    a:hover {
      color: $darkBlueColor;
    }
  }
  a.man:hover {
    color: $darkBlueColor;
  }
  .service:hover {
    .type, .list, .content {
      color: $whiteColor;
    }
    .content {
      background: $darkBlueColor;
      color: $whiteColor;
    }
    .type::after {
      background-color: currentColor;
    }
    .item i {
      color: currentColor;
    }
    &:nth-child(1) .icon {
      background-position: 0 100%;
    }
    &:nth-child(2) .icon {
      background-position: center 100%;
    }
    &:nth-child(3) .icon {
      background-position: 100% 100%;
    }
  }

}