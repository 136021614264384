@import '@/styles/index';

.wrap {
  position: relative;
  padding: 90px 0 40px;
  background-color: #363636;
  color: $whiteColor;
  font-size: 14px;
  line-height: 32px;
  a:hover {
    font-weight: 500;
  }
  .main {
    display: flex;
    align-items: stretch;
    justify-content: space-between;
    margin-bottom: 0;
  }
  .icon {
    font-size: 20px;
  }
  .copy {
    border-top: 1px solid $greyColor;
    padding-top: 40px;
    margin-top: 40px;
    justify-content: left;
  }
  .logo {
    padding-top: 10px;
    img {
      width: 155px;
      height: 82px;
    }
  }
  .border {
    display: block;
    flex-shrink: 0;
    width: 1px;
    background-color: $greyColor;
  }
}

@include xs {
  .wrap {
    text-align: center;
    padding: 25px 0;
    font-size: 12px;
    line-height: 2;
    .logo, .list, .border{
      display: none;
    }
    .contact {
      padding: 0 10px;
      width: 100%;
      box-sizing: border-box;
      text-align: left;
      border-left: none;
    }
    .copy {
      width: calc(100% - 40px);
      line-height: 20px;
      margin: 25px 20px 0;
      padding: 25px 0 0;
      flex-wrap: wrap;
      justify-content: center;
      span {
        display: block;
      }
    }
  }
}