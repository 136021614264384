@import '@/styles/index';
@import '@/styles/wrap';

.tags {
  padding-top: 20px;
  font-weight: 500;
  margin-bottom: 20px;
  border-bottom: 2px solid $borderGreyColor;
  dt {
    font-size: 18px;
    margin-bottom: 10px;
  }
  dd {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 40px;
  }
  .item {
    cursor: pointer;
    text-align: center;
    font-size: 16px;
    font-weight: 400;
    height: 40px;
    padding: 0 30px;
    margin-right: 10px;
    border-radius: 10px;
    line-height: 40px;
    background-color: $bgGreyColor;
    &:hover {
      color: $darkBlueColor;
    }
    &.active {
      font-weight: 500;
      color: $whiteColor;
      background-color: $darkBlueColor;

    }
  }
}
.noData {
  text-align: center;
  padding: 50px;
  color: $lightGreyColor;
}

@include xs {
  .textList {
    padding-top: po(20, 670);
  }
  .tags {
    padding-top: 0;
    font-weight: 400;
    dt {
      font-size: 14px;
    }
    dd {
      margin-bottom: po(30, 670);
    }
    .item {
      font-size: 12px;
      height: 30px;
      line-height: 30px;
      padding: 0 10px;
      border-radius: 5px;
      margin-bottom: 10px;
    }
  }
}
