@charset "utf-8";
@import '@/styles/variables';


@mixin mq($args...) {
  $media-type: 'only screen';
  $media-type-key: 'media-type';
  $args: keywords($args);
  $expr: '';

  @if map-has-key($args, $media-type-key) {
    $media-type: map-get($args, $media-type-key);
    $args: map-remove($args, $media-type-key);
  }

  @each $key, $value in $args {
    @if $value {
      $expr: '#{$expr} and (#{$key}: #{$value})';
    }
  }

  @media #{$media-type} #{$expr} {
    @content;
  }
}

/** 断点区间 **/
@mixin xs() {
  @include mq($min-width: 0, $max-width: 820px) {
    @content;
  }
}

@mixin md() {
  @include mq($min-width: 820px, $max-width: 1775px) {
    @content;
  }
}

@mixin lg() {
  @include mq($min-width: 1776px) {
    @content;
  }
}

/** 全局样式 **/
.flex_1, .flex_2, .flex_3, .flex_4 {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: stretch;
  align-content: space-between;
  margin-bottom: -40px;
  & > * {
    margin-bottom: 40px;
    box-sizing: border-box;
  }
}
.flex_1 {
  margin-bottom: -20px;
  & > * {
    width: 100%;
    margin-bottom: 20px;
  }
}
.flex_2 {
  & > * {
    width: calc((100% - 40px) / 2);
    margin-right: 40px;
    &:nth-child(2n) {
      margin-right: 0;
    }
  }
}
.flex_3 {
  & > * {
    width: calc((100% - 120px) / 3);
    margin-right: 60px;
    &:nth-child(3n) {
      margin-right: 0;
    }
  }
}
.flex_4 {
  & > * {
    width: calc((100% - 99px) / 4);
    margin-right: 33px;
    &:nth-child(4n) {
      margin-right: 0;
    }
  }
}

.main {
  position: relative;
  width: 1200px;
  margin: 0 auto;
  box-sizing: border-box;
  scroll-margin-top: 120px;
}

@include xs {
  .flex_1, .flex_2, .flex_3, .flex_4 {
    margin-bottom: po(-60, 670);
    & > * {
      width: 100%!important;
      margin-right: 0;
      margin-bottom: po(60, 670);
    }
  }
  .main {
    width: 100%;
    padding: 0 20px;
    scroll-margin-top: calc(16vw + 20px);
  }
}
