@import '@/styles/index';
@import '@/styles/wrap';

.contact {
  height: 450px;
  margin-bottom: 40px;
  color: $whiteColor;
  .card {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-right: 50%;
    height: 100%;
    box-sizing: border-box;
    font-size: 16px;
    line-height: 2;
    font-weight: 400;
    background: url(./images/bg_map.jpeg) no-repeat center center;
    background-size: cover;
    .icon {
      position: relative;
      z-index: 2;
      margin: 0 0 35px 50px;
      width: 40px;
      height: 40px;
      background: url(./images/point.png) no-repeat;
      background-size: 100%;
    }
    p {
      position: relative;
      z-index: 2;
      padding-left: 50px;
    }
    &::before {
      content: '';
      position: absolute;
      width: 50%;
      height: 100%;
      top: 0;
      left: 0;
      background-color: rgba(0, 0, 0, .65);
    }
  }
}
.map {
  height: 450px;
  .mapDiv {
    width: 100%;
    height: 100%;
  }
}

@include xs {
  .contact, .map {
    position: relative;
    width: 100%;
    height: 0;
    padding: po(450, 670) 0 0;
  }
  .contact {
    margin-bottom: po(50, 670);
    .card {
      position: absolute;
      width: 100%;
      padding-right: 0;
      font-size: 12px;
      line-height: 2.2;
      top: 0;
      .icon {
        width: po(50);
        height: 0;
        padding-top: po(50);
        margin: 0 0 po(40, 670)  po(40, 670);
      }
      p {
        padding:0 po(40, 670);
      }
      &::before {
        width: 100%;
      }
    }
  }
  .map {
    .mapDiv {
      position: absolute;
      top: 0;
      left: 0;
    }
  }
}