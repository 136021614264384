@import '@/styles/media-queries';

i.icon {
  display: inline-block;
  vertical-align: middle;
  width: 20px;
  height: 20px;
  margin-right: 10px;
  &.icon_mail, &.icon_address, &.icon_phone {
    background-repeat: no-repeat;
    background-size: 1em;
    background-image: url(./images/icon_copy.png);
  }
  &.icon_mail {
    background-position: 0 0;
  }
  &.icon_address {
    background-position: 0 -1em;
  }
  &.icon_phone {
    background-position: 0 -2em;
  }
  &.icon_close {
    position: relative;
    margin-right: 0;
    &::before, &::after {
      content: '';
      position: absolute;
      left: 50%;
      top: 50%;
      background-color: currentColor;
      transform: translate3d(-50%, -50%, 0) rotate3d(0, 0, 1, 45deg);
    }
    &::before {
      width: 1px;
      height: 100%;
    }
    &::after {
      width: 100%;
      height: 1px;
    }
    &.turn_down {
      transform: rotateZ(90deg);
    }
  }
  &.icon_right {
    position: relative;
    margin-right: 0;
    transform-origin: center center;
    transition: transform .3s ease-in-out;
    font-size: 5px;
    &::before {
      content: '';
      position: absolute;
      width: 1em;
      height: 1em;
      top: 50%;
      left: 50%;
      border-right: 2px solid currentColor;
      border-bottom: 2px solid currentColor;
      transform: translate3d(-50%, -50%, 0) rotate3d(0, 0, 1, -45deg);
    }
    &.turn_down {
      transform: rotateZ(90deg);
    }
  }
  &.icon_right_double {
    position: relative;
    margin-right: 0;
    font-size: 5px;
    &::before, &::after {
      content: '';
      position: absolute;
      width: 1em;
      height: 1em;
      top: 50%;
      border-right: 1px solid currentColor;
      border-bottom: 1px solid currentColor;
      transform: translate3d(-50%, -50%, 0) rotate3d(0, 0, 1, -45deg);
    }
    &::before {
      left: 30%;
    }
    &::after {
      left: 50%;
    }
    &.turn_down {
      transform: rotateZ(90deg);
    }
  }
  &.icon_triangle {
    background-image: url(./images/icon_trangle.png);
    background-repeat: no-repeat;
    background-size:auto 100%;
  }
}

@include xs {
  i.icon {
    &.icon_right, &.icon_right_double {
      font-size: 8px;
    }
  }
}