@import '@/styles/index';
@import '@/styles/wrap';

.text {
  font-size: 18px;
  font-weight: 400;
  line-height: 2;
  text-indent: 2em;
}
.flex_2 {
  &.border > * {
    border-bottom: 1px solid $borderGreyColor;
  }
}
.info {
  margin-bottom: 30px;
}

.flex_4 {
  justify-content: left;
}

@include xs {
  .text {
    font-size: 16px;
  }
  .info {
    margin-bottom: 10px;
  }
  .box {
    border-top: none;
    padding-top: 0;
  }
  .flex_2 {
    &.border > * {
      &:last-child {
        border: none;
      }
    }
  }
  .flex_4 {
    justify-content: space-between;
    & > * {
      width: calc(50% - 8px)!important;
      &:nth-child(2n) {
        margin-right: 0;
      }
    }
  }
}