@import '@/styles/index';

.banner {
  position: relative;
  width: 100%;
  height: 300px;
  min-width: 1200px;
  background-position: center 0;
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  align-items: center;
  text-align: center;
  font-weight: 500;
  font-size: 50px;
  color: $whiteColor;
  text-shadow: 0 0 10px $darkBlueColor;
  &::after {
    content: attr(data-name);
    width: 100%;
  }
}
@include xs {
  .banner {
    height: 0;
    padding-top: po(305);
    font-size: 25px;
    min-width: auto;
    &::after {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
    }
  }
}