$darkBlueColor: #688CA8;
$lightBlueColor: #E8F1F7; // 不能改

$blackBlueColor: #306286;
$middleBlueColor: #D8E3EB;
$blueColor: #afc4d4;



$greyColor: #5F5F5F;
$middleGreyColor: #979797;
$lightGreyColor: #b3b3b3;

$borderGreyColor: #e8e8e8;
$bgGreyColor: #f8f8f8;

$whiteColor: #ffffff;
$darkWhiteColor: #FAFAFA;
$blackColor: #333;
$lightBlackColor: #2a2a2a;

@function po($width, $base: 750) {
  @return calc($width / $base) * 100%;
}