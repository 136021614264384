@import '@/styles/index';

.wrap {
  height: 80px;
  width: 100%;
  position: fixed;
  top: 0;
  z-index: 999;
}
.nav {
  position: relative;
  z-index: 2;
  background-color: #fff;
  box-shadow: 0px 4px 7px 0px rgba(0, 0, 0, 0.05);
  .main {
    display: flex;
    width: 100%;
    max-width: 1200px;
    justify-content: space-between;
    align-items: flex-end;
    height: 80px;
    margin-bottom: 0;
  }
  .logo {
    width: 280px;
    flex-shrink: 1;
    align-self: center;
    img {
      display: block;
      width: 100%;
      aspect-ratio: 329 / 75;
    }
  }
  .list {
    font-size: 16px;
    color: $blackColor;
    display: flex;
    align-items: flex-end;
    .item {
      position: relative;
      display: inline-flex;
      align-items: center;
      line-height: 20px;
      height: 20px;
      padding: 10px 20px 30px;
      font-weight: 500;
      white-space: nowrap;
      i {
        font-size: 5px;
      }
    }
    .icon_close {
      display: none;
    }
  }
}
.pcSubNav {
  width: 100%;
  position: absolute;
  color: $darkBlueColor;
  background-color: #fafafa;
  bottom: 0;
  left: 0;
  box-shadow: 0px 4px 7px 0px rgba(0, 0, 0, 0.05);
  animation: fadeDown ease-out 300ms forwards;
  .main {
    padding: 10px 0 20px;
    max-width: 1200px;
    display: flex;
    margin-bottom: 0;
    justify-content: space-between;
  }
  .title {
    font-size: 30px;
    line-height: 2;
  }
  &.hideSubNav {
    animation: fadeUp ease-out 300ms forwards;
  }
  @keyframes fadeDown {
    0% {
      transform: translateY(0);
    }
    100% {
      transform: translateY(100%);
    }
  }
  @keyframes fadeUp {
    0% {
      transform: translateY(100%);
    }
    100% {
      transform: translateY(0);
    }
  }
}
.mSubNav {
  display: none;
}
.subItem {
  width: 390px;
  margin-right: 255px;
  font-size: 14px;
  line-height: 32px;
  box-sizing: border-box;
  p {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid $borderGreyColor;
    transition: all 0.3s ease-in-out;
    a {
      flex-grow: 1;
    }
    i {
      color: $blueColor;
    }
    &:hover {
      font-weight: 700;
      padding-left: 10px;
    }
    &:last-child {
      border-bottom: none;
    }
  }
}

@include mq($min-width: 768px, $max-width: 1100px) {
  .main {
    padding: 0 20px;
  }
  .nav {
    .list .item {
      font-size: 16px;
      padding: 10px 10px 30px;
    }
  }
  .subNav .sub {
    margin-right: 0px;
  }
}
@include mq($min-width: 820px) {
  .nav .list .item {
    .active, &:hover, .active + i {
      color: $darkBlueColor;
    }
  }
  .nav_button {
    display: none;
  }
}
@include xs {
  .wrap {
    height: 0;
    padding-top: po(120);
  }
  .pcSubNav {
    display: none;
  }
  .mSubNav {
    display: none;
    margin: 0 20px;
  }
  .active + .mSubNav {
    display: block;
  }
  .nav {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    .main {
      position: absolute;
      top: 0;
      width: 100%;
      height: 100%;
    }
    .nav_button {
      display: block;
      position: absolute;
      width: po(68);
      height: 0;
      padding-top: po(48);
      top: 50%;
      right: po(36);
      transform: translateY(-50%);
      border-top: 2px solid $darkBlueColor;
      border-bottom: 2px solid $darkBlueColor;
      &::after {
        content: '';
        position: absolute;
        width: 100%;
        height: 0;
        top: 50%;
        transform: translateY(-1px);
        border-bottom: 2px solid $darkBlueColor;
      }
    }
    .logo {
      position: absolute;
      left: po(35);
      top: 50%;
      width: po(344);
      height: 0;
      padding: po(82) 0 0;
      transform: translateY(-50%);
      img {
        position: absolute;
        width: 100%;
        top: 0;
        left: 0;
      }
    }
    .list {
      position: fixed;
      display: block;
      overflow-y: auto;
      padding-top: 60px;
      box-sizing: border-box;
      background-color: $darkBlueColor;
      left: 0;
      top: 0;
      right: 0;
      bottom: 0;
      color: $whiteColor;
      font-size: 20px;
      transition: all 0.3s ease-in-out;
      transform: translateX(100%);
      &.showNav {
        transform: translateX(0);
      }
      .item {
        display: flex;
        justify-content: space-between;
        padding: 25px;
        margin: 0 20px;
        border-bottom: 1px solid $whiteColor;
        &.active {
          background-color: #5E85A3;
        }
        i {
          font-size: 8px;
        }
        .active, &:active, &:hover {
          color: $blueColor;
        }
      }
      .subItem {
        width: 100%;
        padding: po(25, 670) 0 po(25, 670);
        margin: 0;
        font-size: 16px;
        line-height: 40px;
        border-bottom: 1px solid $whiteColor;
        p a {
          display: block;
          padding-left: po(100, 670);
        }
        p {
          border: none;
        }
        i {
          display: none;
        }
      }
      .icon_close {
        display: block;
        position: absolute;
        top: 20px;
        left: 20px;
      }
    }
  }
}